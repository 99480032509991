import { Dayjs } from 'dayjs'

import { DEFAULT_FORMAT } from '../Constant'
import { RidaApiService } from '../RidaApi'
import {
  V1RidaApiGetSassFleetOperatorReportEarningForDateRangeResponse,
  V1RidaApiGetSassFleetOperatorReportJobPerDayTimelineResponse,
} from '../Type'

class SassFleetOperatorReport {
  public static async generateEarningForDateRange(
    startDate: Dayjs,
    endDate: Dayjs,
  ) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorReportEarningForDateRangeResponse>(
        'v1/report/earningForDateRange',
        {
          start_date: startDate.format(DEFAULT_FORMAT.DATE),
          end_date: endDate.format(DEFAULT_FORMAT.DATE),
        },
      )

    return response.data.data
  }

  public static async getJobPerUserForTimelineView(date: Dayjs) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorReportJobPerDayTimelineResponse>(
        'v1/report/jobPerDayTimeline',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
        },
      )

    return response.data.data
  }
}

export { SassFleetOperatorReport as SassFleetOperatorReportService }
