import { Dayjs } from 'dayjs'
import { RidaApiService } from './RidaApi'
import _ from 'lodash'
import {
  V1RidaApiGetUserEmailSentBatchIdUserEmailSentResponse,
  V1RidaApiGetUserEmailSentBatchResponse,
} from './Type'
import { DEFAULT_FORMAT } from './Constant'

class UserEmail {
  public static async sendEmailPayslip(data: {
    paymentPeriodStart: Dayjs
    paymentPeriodEnd: Dayjs
    paymentDate: Dayjs
    userEmailSentType: number
    users: {
      id: string
      name: string
      email: string
      sections: {
        name: string
        removeLineItemsWhenZeroAmount?: boolean
        lineItems: {
          name: string
          amount: string
        }[]
      }[]
    }[]
  }) {
    await RidaApiService.post('v1/userEmail/sendPayslipEmail', {
      payment_period_start: data.paymentPeriodStart.format(DEFAULT_FORMAT.DATE),
      payment_period_end: data.paymentPeriodEnd.format(DEFAULT_FORMAT.DATE),
      payment_date: data.paymentDate.format(DEFAULT_FORMAT.DATE),
      user_email_sent_type: data.userEmailSentType,
      users: _.map(data.users, u => {
        return {
          id: u.id,
          email: u.email,
          name: u.name,
          sections: _.map(u.sections, s => {
            return {
              remove_line_items_when_zero_amount:
                s.removeLineItemsWhenZeroAmount || false,
              name: s.name,
              line_items: s.lineItems,
            }
          }),
        }
      }),
    })
  }

  public static async getAllEmailSentBatches(filterOptions: {
    pageNumber: number
    itemsPerPage: number
  }) {
    const response =
      await RidaApiService.get<V1RidaApiGetUserEmailSentBatchResponse>(
        'v1/userEmailSentBatch',
        {
          items_per_page: filterOptions.itemsPerPage,
          page_number: filterOptions.pageNumber,
        },
      )

    return response.data.data
  }

  public static async getAllEmailSentFromBatch(
    batchId: string,
    filterOptions: {
      pageNumber: number
      itemsPerPage: number
      userEmailStatusIds?: number[]
    },
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetUserEmailSentBatchIdUserEmailSentResponse>(
        `v1/userEmailSentBatch/${batchId}/userEmailSent`,
        {
          items_per_page: filterOptions.itemsPerPage,
          page_number: filterOptions.pageNumber,
          user_email_sent_status_id: filterOptions.userEmailStatusIds,
        },
      )

    return response.data.data
  }

  public static async cancelEmailBatch(batchId: string) {
    await RidaApiService.post(`v1/userEmailSentBatch/${batchId}/cancel`)
  }
}

export { UserEmail as UserEmailService }
