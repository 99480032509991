export const DEFAULT_FORMAT = {
  DATE: 'YYYY-MM-DD',
  DATE_2: "DD MMM 'YY",
  DATE_3: 'DD/MM/YYYY',
  DATE_TIME: 'YYYY/MM/DD hh:mmA',
  DATE_TIME_2: "DD MMM 'YY h:mm A",
  DATE_TIME_3: 'llll',
  DATE_TIME_4: 'YYYY-MM-DD HH:mm',
  TIME_ONLY: 'h:mm A',
  TIME_ONLY_2: 'HH:mm',
}
export const TZ = {
  SG: 'Asia/Singapore',
}
export const DEFAULT_PHONE_COUNTRY_CODE = '+65'
export const DOCUMENT_TYPE = {
  PROOF_OF_DELIVERY: 1,
  PROFILE_PICTURE: 2,
}
export const DEFAULT_TZ = TZ.SG
export const NUXT_EVENT = {
  DRAWER_OPEN: 'event:nuxt:drawer-open',
  UPDATE_SIDEBAR_NOTIFICATION: 'event:updateSidebarNotification',
}
export const USER_ROLE_TYPE = {
  ADMIN: 1,
  OPS: 2,
  SUPPORT: 3,
  FREELANCE_DRIVER: 4,
  FULLTIME_DRIVER: 6,
  SELF_MANAGED_OWNER: 7,
  SELF_MANAGED_DRIVER: 8,
  SASS_FLEET_OPERATOR_OWNER: 9,
  SASS_FLEET_OPERATOR_DRIVER: 10,
  FREELANCE_PRIORITY_DRIVER: 11,
}
export const VEHICLE_TYPE = {
  VAN: 1,
  CAR: 2,
  BIKE: 3,
}
export const CLIENT_JOB_STATUS_TYPE = {
  ACCEPTED: 1,
  COMPLETED: 2,
  CANCELLED: 3,
  ASSIGNING: 4,
  LATE: 5,
  ONGOING: 6,
  REJECTED: 7,
  FAIL: 8,
}
export const JOB_STATUS_TYPE = {
  PENDING: 10,
  LOOKING_FOR_DRIVER: 20,
  ACCEPTED: 30,
  REJECTED_WITH_NO_DRIVER_HAS_OFFER_SENT_TO: 40,
  REJECTED_WITH_ALL_OFFERS_REJECTED: 50,
  REJECTED_WITH_OFFER_EXPIRED: 60,
  ONGOING: 70,
  COMPLETED: 80,
  FAILED: 90,
  CANCELLED_BY_CUSTOMER: 100,
  CANCELLED_BY_ADMIN: 110,
  CANCELLED_BY_DRIVER: 120,
}
export const TASK_DELIVERY_STATUS_TYPE = {
  PENDING: 10,
  STARTED: 20,
  REACHED: 25,
  COMPLETED: 30,
  FAILED: 40,
  CANCELLED_BY_CUSTOMER: 50,
  CANCELLED_BY_ADMIN: 60,
  EXPIRED: 70,
  REJECTED: 80,
}
export enum PUSHER_CHANNEL {
  LIVE_ETA = 'live_eta',
}
export enum PUSHER_EVENT {
  UPDATED = 'updated',
}
export const DRIVER_EARNING_TYPE = {
  BASE_EARNING: 1,
  WAITING_FEE: 2,
}
export const SIGN_TYPE = {
  POSITIVE: 1,
  NEGATIVE: 2,
  ALL: 3,
}
export const STOP_TYPE = {
  PICK_UP: 1,
  DROP_OFF: 2,
}
export const USER_STATUS_TYPE = {
  ACTIVE: 1,
  INACTIVE: 2,
}
export const ORDER_SERVICE_TYPE = {
  STANDARD: 1,
  SELF_MANAGED: 2,
}
export const VALUE_TYPE = {
  PERCENTAGE: 'percentage',
  FIXED: 'fixed',
}
export const REGION = {
  CBD: 'CBD',
  SOUTH: 'SOUTH',
  CENTRAL: 'CENTRAL',
  NORTH: 'NORTH',
  EAST: 'EAST',
  WEST: 'WEST',
  NORTH_EAST: 'NORTH_EAST',
}
export const ITEM_CATEGORY_TYPE = {
  MEDICINE: 1,
  FOOD: 2,
  WINE: 3,
  OTHERS: 100,
}
export const DRIVER_EQUIPMENT_TYPE = {
  THERMAL_BAG: 1,
  TROLLEY: 2,
}
export const USER_ACCEPTED_STATUS_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
}
export const USER_EMAIL_SENT_STATUS_TYPE = {
  PENDING: 10,
  IN_PROGRESS: 15,
  SUCCESS: 20,
  FAILED: 30,
  ADMIN_CANCELED: 40,
}
export const USER_EMAIL_TYPE = {
  PAYSLIP_WEEKLY: 1,
  PAYSLIP_MONTHLY: 2,
}
